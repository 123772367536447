@import "../../../styles/index.scss";

.title {
  @include title_text();
}

.container {
  @include flex(
    $--flex-direction-column,
    $--justify-content-start,
    $--align-items-start
  );
  gap: 0.9rem;
  
}

.question {
  @include title_question();
}

.flex {
  // border: 1px solid red;
  width: 100%;
}

.container_2{
    @include flex(
        $--flex-direction-column,
        $--justify-content-start,
        $--align-items-start
      );
      // flex-wrap: wrap !important;
    gap: 0.9rem;
    margin-top: 1rem;
  //  border: 1px solid red;  
}
.wrapper{
  margin-top: 1rem;
  height: 100%;
}

@media screen and (max-width:500px) {
  .container {
      gap: 0.4rem;
  }
  .container_2{ 
    // flex-wrap: wrap !important;
    gap: 0.4rem;
    margin-top: .4rem;
//    border: 1px solid red;  
}

.wrapper{
  // border: 1px solid red;
}
}