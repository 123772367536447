@import "../../../styles/index.scss";


.cancelButton{   
    @include cancelTicket();
    padding: .9rem;
}
.verticalDivide{
    @include verticalFlex(.9rem);
    align-items: flex-start;
}

.font_Style{
    font-family: $--font-primary;
    font-size: $--font-size-xl;
    font-weight: $--font-weight-regular;
    
}
