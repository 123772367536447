@import "../../../styles/index.scss";


.title {
color: $--color-heading;
@include title_text();
}

.question {
@include title_question()
}
.slider{
    min-width: 70%;
}
.background{
    background: rgba(210, 240, 242, 0.40);
}

@media screen and (max-width:500px) {
    .slider{
        width: 100%;
    }
}