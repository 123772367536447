.card_wrapper {
    border: 1px solid #DDD;
    background: #FFF;
    padding: 1rem;
}
.flex{
    display: flex;
    // width: 50% !important;
    gap: 2rem;
    // border: 1px solid red;

}
.flex div{
//    display: inline-flex; 
//    border: 1px solid rgb(4, 0, 255);
   width: auto !important;
}

.name{
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25rem;
}
.specialty{
    font-size: 10px;
    font-weight: 400;
    background-color: #ffe0f0;
    color: #ff69b4;
    padding: 2px 8px;
    border-radius: 12px;
    width: auto !important;
    height: auto !important;
    
}
.doctor_det{
    display: flex;
    flex-direction: column;
    gap: .5rem;
}
.card_divider{
    gap: 10px;
    flex-wrap: wrap;
}
.timing{
    font-size: 1rem; 
    word-wrap: break-word;
}
.timing_divider{
    flex-wrap: wrap;
}

@media screen and (max-width:500px) {
    .name{
        font-size: .75rem;        
    }
    // .specialty{
    //    border: 2px solid red;
    // }
    .card_divider{
        gap: 5px;
    }
    .timing{
        font-size: .75rem; 
    }
    
    
}
