
.fontStyles{
    color: #333;
    font-variant-numeric: lining-nums tabular-nums;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 120% */
    letter-spacing: 1.2px;
}
.heading{
color: #333;
margin-bottom: 1rem;
font-variant-numeric: lining-nums tabular-nums;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 120% */
letter-spacing: 1.2px;
}
.card_align{
  display: flex;
  gap: 4rem;
}

@media screen and (max-width:500px) {
    .fontStyles{
        font-size: 12px;
      }
      .heading{
        font-size:14px ;
      }
      .card_align{
        flex-direction: column;
        gap: 1rem;
      }
}