@import "../../../styles/index.scss";
.question{
    @include custom-font($--font-primary, $--font-weight-regular, $--font-size-lg, $--line-height-lg, $--letter-spacing-lg, $--letter-spacing-lg);
    color: #333333;
    font-style: normal;
}
.ans{
    @include custom-font($--font-primary, $--font-weight-semibold, $--font-size-lg, $--line-height-lg, $--letter-spacing-lg, $--letter-spacing-lg);
    color: #333333;
    font-style: normal;
}
.wrapper{
    display: inline-flex;
    gap: 1rem;
}

@media screen and (max-width:500px) {
    .question{
        @include custom-font($--font-primary, $--font-weight-regular, $--font-size-xxs, $--line-height-lg, $--letter-spacing-lg, $--letter-spacing-lg);
        color: #333333;
        font-style: normal;
    }
    .ans{
      font-size: $--font-size-xxs;
    }
    
    
}