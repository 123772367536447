@import "../../../styles/index.scss";

.wrapper {
  color: $--color-bg-black;
  min-width: 100%;
}

.title {
  @include custom-font(
    $--font-primary,
    $--font-weight-semibold,
    $--font-size-md,
    $--line-height-lg,
    $--letter-spacing-lg,
    $--letter-spacing-lg
  );
}

.icon {
  width: 100%;
}

.container {
  @include border(
    $--size-xxs,
    $--border-style-solid,
    $--color-primary,
    $--border-radius-xxs
  );
  min-width: 100%;
  display: flex;
  padding-left: 1rem;
  padding-right: 1rem;
}

.ticket_status {
 
  color: #4872d5;
  border-radius: 10px;
  border: 1px solid #4872d5;
  background: rgba(72, 114, 213, 0.2);
  padding: 0.5rem 0.5rem; 
  font-size: 1.125rem;
}

.flex_size {
  max-width: 50%;
//   border: 1px solid red;
  justify-content: flex-start;
}

.flex_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  
}
.ticket_status_mini{
    display: none;
}
.visible{
    display: block;
    max-width:  100%;
}


@media screen and (max-width: 500px) {
    .flex_size {
        max-width: 100%;

      }
  .container {
    border: none;
  }
  .visible{
    display: none;
}
.ticket_status_mini{
    display: block;
    color: #4872d5;
  }
}
