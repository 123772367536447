$--color-primary:#00A7B5;
$--color-layout-bg:#D2F0F252;
$-color-wa-bg:#D3EFD2A8;
$--color-text-primary:#333333;
$--color-text-secondary:#17AB13;
$--color-text-tertiary:#F0389C;
$--color-heading:#385BF0;
$--color-bg-progress:#4872D5;
$--color-bg-light:#FDDEEF57;
$--color-bg-pink:#FDDEEF;
$--color-bg-pink-dark:#FDDEEFA8;
$--color-bg-location:#D2F0F266;
$--color-bg-green:#D2F0F2;
$--color-bg-white:#FFFFFF;
$--color-bg-black:#000000;
$--color-bg-light-gray:#919598;
$--color-bg-hamburger:rgba(82, 80, 80, 0.37);
$--color-bg-lighter-gray:rgba(51, 51, 51, 0.70);
$--color-bg-wa:rgba(211, 239, 210, 0.66);
$--color-button:#061DB2;
//font//
$--font-primary: 'Poppins', sans-serif;
//font-weight//
$--font-weight-regular: 400;
$--font-weight-medium: 500;
$--font-weight-semibold: 600;
$--font-weight-bold: 700;
$--font-weight-extrabold: 800;
$--font-weight-black: 900;
//font-size//
// $base-font-size: 16px; // Define your base font size here
$--font-size-xxxs:0.625rem;
$--font-size-xxs: 0.75rem; // 12px / 16px = 0.75rem
$--font-size-xs: 0.875rem; // 14px / 16px = 0.875rem
$--font-size-sm: 1rem; // 16px / 16px = 1rem
$--font-size-md: 1.125rem; // 18px / 16px = 1.125rem
$--font-size-lg: 1.25rem; // 20px / 16px = 1.25rem
$--font-size-xl: 1.375rem; // 22px / 16px = 1.375rem
$--font-size-xxl: 1.5rem; // 24px / 16px = 1.5rem
$--font-size-xxxl: 1.625rem; // 26px / 16px = 1.625rem
$--font-size-xxxxl: 1.75rem; // 28px / 16px = 1.75rem
$--font-size-xxxxxl: 1.875rem; // 30px / 16px = 1.875rem

//line-height//
$--line-height-xxs: 0.75rem;
$--line-height-xs: 0.875rem;
$--line-height-sm: 1rem;
$--line-height-md: 1.125rem;
$--line-height-lg: 1.25rem;
$--line-height-xl: 1.375rem;
$--line-height-xxl: 1.5rem;
$--line-height-xxxl: 1.625rem;
$--line-height-xxxxl: 1.75rem;
$--line-height-xxxxxl: 1.875rem;

//paragraph//
$--paragraph-xxs: 0.75rem;
$--paragraph-xs: 0.875rem;
$--paragraph-sm: 1rem;
$--paragraph-md: 1.125rem;
$--paragraph-lg: 1.25rem;
$--paragraph-xl: 1.375rem;
$--paragraph-xxl: 1.5rem;
$--paragraph-xxxl: 1.625rem;
$--paragraph-xxxxl: 1.75rem;
$--paragraph-xxxxxl: 1.875rem;

//letter-spacing//
$--letter-spacing-xxs: 0.03rem;
$--letter-spacing-xs: 0.04rem;
$--letter-spacing-sm: 0.05rem;
$--letter-spacing-md: 0.06rem;
$--letter-spacing-lg: 0.07rem;
$--letter-spacing-xl: 0.08rem;
$--letter-spacing-xxl: 0.09rem;
$--letter-spacing-xxxl: 0.1rem;
$--letter-spacing-xxxxl: 0.11rem;
$--letter-spacing-xxxxxl: 0.12rem;

//width//
$--width-xxs: 0.75rem;
$--width-xs: 0.875rem;
$--width-sm: 1rem;
$--width-md: 1.125rem;
$--width-lg: 1.25rem;
$--width-xl: 1.375rem;
$--width-xxl: 1.5rem;
$--width-xxxl: 1.625rem;
$--width-xxxxl: 1.75rem;
$--width-xxxxxl: 1.875rem;

//height//
$--height-xxs: 0.75rem;
$--height-xs: 0.875rem;
$--height-sm: 1rem;
$--height-md: 1.125rem;
$--height-lg: 1.25rem;
$--height-xl: 1.375rem;
$--height-xxl: 1.5rem;
$--height-xxxl: 1.625rem;
$--height-xxxxl: 1.75rem;
$--height-xxxxxl: 1.875rem;

//margin//
$--margin-xxs: 0.75rem;
$--margin-xs: 0.875rem;
$--margin-sm: 1rem;
$--margin-md: 1.125rem;
$--margin-lg: 1.25rem;
$--margin-xl: 1.375rem;
$--margin-xxl: 1.5rem;
$--margin-xxxl: 1.625rem;
$--margin-xxxxl: 1.75rem;
$--margin-xxxxxl: 1.875rem;

//padding//
$--padding-xxs: 0.75rem;
$--padding-xs: 0.875rem;
$--padding-sm: 1rem;
$--padding-md: 1.125rem;
$--padding-lg: 1.25rem;
$--padding-xl: 1.375rem;
$--padding-xxl: 1.5rem;
$--padding-xxxl: 1.625rem;
$--padding-xxxxl: 1.75rem;
$--padding-xxxxxl: 1.875rem;

//border-radius//
$--border-radius-xxs: 0.25rem;
$--border-radius-xs: 0.375rem;
$--border-radius-sm: 0.5rem;
$--border-radius-md: 0.625rem;
$--border-radius-lg: 0.75rem;
$--border-radius-xl: 0.875rem;
$--border-radius-xxl: 1rem;
$--border-radius-xxxl: 1.125rem;
$--border-radius-xxxxl: 1.25rem;
$--border-radius-xxxxxl: 1.375rem;
$--border-radius-xxxxxxl: 1.5rem;

//space//
$--space-xxs: 0.75rem;
$--space-xs: 0.875rem;
$--space-sm: 1rem;
$--space-md: 1.125rem;
$--space-lg: 1.25rem;
$--space-xl: 1.375rem;
$--space-xxl: 1.5rem;
$--space-xxxl: 1.625rem;
$--space-xxxxl: 1.75rem;
$--space-xxxxxl: 1.875rem;

//z-index//
$--z-index-xxs: 1;
$--z-index-xs: 2;
$--z-index-sm: 3;
$--z-index-md: 4;
$--z-index-lg: 5;
$--z-index-xl: 6;
$--z-index-xxl: 7;
$--z-index-xxxl: 8;
$--z-index-xxxxl: 9;
$--z-index-xxxxxl: 10;

//position//
$--position-absolute: absolute;
$--position-relative: relative;
$--position-fixed: fixed;
$--position-sticky: sticky;

//text-align//
$--text-align-left: left;
$--text-align-center: center;
$--text-align-right: right;

//justify-content//
$--justify-content-start: flex-start;
$--justify-content-end: flex-end;
$--justify-content-center: center;
$--justify-content-between: space-between;
$--justify-content-around: space-around;

//align-items//
$--align-items-start: flex-start;
$--align-items-end: flex-end;
$--align-items-center: center;
$--align-items-stretch: stretch;

//flex-direction//
$--flex-direction-row: row;
$--flex-direction-row-reverse: row-reverse;
$--flex-direction-column: column;
$--flex-direction-column-reverse: column-reverse;

//breakpoint//
$--breakpoint-sm: 576px;
$--breakpoint-md: 768px;
$--breakpoint-lg: 992px;
$--breakpoint-xl: 1200px;
$--breakpoint-xxl: 1400px;

//general-size//
$--size-xxs: 0.05rem;
$--size-xs: 0.875rem;
$--size-sm: 1rem;
$--size-md: 1.125rem;
$--size-lg: 1.25rem;
$--size-xl: 1.375rem;
$--size-xxl: 1.5rem;
$--size-xxxl: 1.625rem;
$--size-xxxxl: 1.75rem;

//border-style//

$--border-style-none: none;
$--border-style-solid: solid;
$--border-style-dashed: dashed;
$--border-style-dotted: dotted;











