.wrapper {
  width: 100%;
  margin-bottom: 1.5rem;
}
.container{
  padding-top: 1rem;
}

.table {
  height: 65vh; /* Adjust height based on header and other fixed elements */
  overflow-y: auto; /* Make the table scrollable */
 
}

.table::-webkit-scrollbar {
  width: .8rem; /* Width of the scrollbar */
  
}

.table::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the scrollbar track */
  border-radius: 10px;
}

.table::-webkit-scrollbar-thumb {
  background-color:#00A7B5;
  border-radius: 10px; 
  border: 3px solid #f1f1f1; 

}

.table::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar thumb on hover */
}




@media screen and (max-width: 768px) {
  .table {
    height: 46vh; /* Adjust height based on header and other fixed elements */
  }

  
}

@media screen and (max-width:560px) {
  .head_Card{
    display: none;
  };


}
