@import "../../styles/variables/variables.scss";
@mixin background-with-opacity($color, $opacity) {
    background-color: rgba(red($color), green($color), blue($color), $opacity);
}

// @mixin button($properties) {
//     $defaults: (
//         bg-color: #007bff,
//         text-color: #ffffff,
//         hover-bg-color: #0056b3,
//         hover-text-color: #ffffff,
//         border: none,
//         border-radius: 6px,
//         padding: 12px 24px,
//         font-size: 16px,
//         font-weight: bold,
//         cursor: pointer
//     );

//     $merged-props: map-merge($defaults, $properties);

//     background-color: map-get($merged-props, bg-color);
//     color: map-get($merged-props, text-color);
//     border: map-get($merged-props, border);
//     border-radius: map-get($merged-props, border-radius);
//     padding: map-get($merged-props, padding);
//     font-size: map-get($merged-props, font-size);
//     font-weight: map-get($merged-props, font-weight);
//     cursor: map-get($merged-props, cursor);
//     transition: background-color 0.3s ease, color 0.3s ease;

//     &:hover {
//         background-color: map-get($merged-props, hover-bg-color);
//         color: map-get($merged-props, hover-text-color);
//     }
// }

@mixin button($bgColor,$color,$border,$borderRadius,$padding,$fontSize,$fontWeight,$hoverBgColor,$hoverColor) {
    background-color: $bgColor;
    color: $color;
    border: $border;
    border-radius:  $borderRadius;
    padding:0 $padding;
    font-size: $fontSize;
    font-weight: $fontWeight;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    &:hover{
        background-color: $hoverBgColor;
        color: $hoverColor;
    }
}

@mixin disable_btn(){
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: $--font-size-xl;
    font-weight: $--font-weight-medium;
    color: $--color-bg-white;
    padding: 1rem 1.5rem;
    background: $--color-bg-light-gray;
    line-height: 24px;
    /* 109.091% */
    letter-spacing: 1.32px;
}

@mixin cancelTicket(){
    background-color: red;
    color: white;
    font-size:$--font-size-xl;
    font-weight:  $--font-weight-regular;
   
    // border: 1px solid $--color-bg-progress;
    // width: auto;
    &:hover{
        background-color:red ;
        color: white;
    }
}
@mixin cursor-pointer() {
    cursor: pointer;
}

@mixin custom-font($family, $weight, $size, $line-height, $paragraph-spacing, $letter-spacing) {
    font-family: $family;
    font-weight: $weight;
    font-size: $size;
    line-height: $line-height;
    margin-bottom: $paragraph-spacing;
    letter-spacing: $letter-spacing;
}

@mixin border($width, $style, $color,$radius: 0) {
    border-width: $width;
    border-style: $style;
    border-color: $color;
    border-radius: $radius;
}

@mixin box-shadow($shadow, $color) {
    box-shadow: $shadow $color;
}

@mixin flex($direction, $justify, $align) {
    @debug "Direction: #{$direction}, Justify: #{$justify}, Align: #{$align}";
    display: flex;
    // flex-wrap: wrap;
    flex-direction: $direction;
    justify-content: $justify;
    align-items: $align;
}

@mixin flex-split($sizes, $gap: 0) {
    display: flex;
    flex-wrap: nowrap;
    margin: ($gap / -2);

    @for $i from 1 through length($sizes) {
        $size: nth($sizes, $i);
        flex: 0 0 $size;
        margin: ($gap / 2);
    }
}

@mixin grid($columns, $gap: 0) {
    display: grid;
    grid-template-columns: repeat($columns, 1fr);
    gap: $gap;
}

@mixin position($position, $top, $right, $bottom, $left) {
    position: $position;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
}

@mixin backGround($color) {
    background-color: $color;
}

@mixin centerBlockElem(){
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin verticalFlex($gap){
    display: flex;
   flex-direction: column;
  gap: $gap;
}


@mixin margin($top, $right, $bottom, $left) {
    margin-top: $top;
    margin-right: $right;
    margin-bottom: $bottom;
    margin-left: $left;
}

@mixin padding($top, $right, $bottom, $left) {
    padding-top: $top;
    padding-right: $right;
    padding-bottom: $bottom;
    padding-left: $left;
}

@mixin text($size, $align,$weight ,$color) {
    font-size: $size;
    text-align: $align;
    color: $color;
    font-weight: $weight;
    // font-size: 1rem;
    // text-align: center;
    // font-weight: 500;
}



@mixin title_text(){
    @include custom-font($--font-primary, $--font-weight-medium, $--font-size-xl, $--line-height-lg, $--letter-spacing-lg, $--letter-spacing-lg);
    font-variant-numeric: lining-nums tabular-nums;

    @media screen and (max-width: 500px) {
        @include custom-font($--font-primary, $--font-weight-medium, $--font-size-xs, $--line-height-lg, $--letter-spacing-lg, $--letter-spacing-lg);
        font-variant-numeric: lining-nums tabular-nums;
    }
    

}

@mixin title_question(){

    @include custom-font($--font-primary, $--font-weight-regular, $--font-size-lg, $--line-height-lg, $--letter-spacing-lg, $--letter-spacing-lg);
    font-variant-numeric: lining-nums tabular-nums;

    @media screen and (max-width: 500px) {
        @include custom-font($--font-primary, $--font-weight-medium, $--font-size-xxxs, $--line-height-lg, $--letter-spacing-lg, $--letter-spacing-lg);
        font-variant-numeric: lining-nums tabular-nums;
    }
}


@mixin heading(){
    color: $--color-heading;
        @include custom-font(
          $--font-primary,
          $--font-weight-bold,
          $--font-size-xl,
          $--line-height-lg,
          $--letter-spacing-lg,
          $--letter-spacing-lg
        );
       
      

      @media screen and (max-width:500px){

     
            @include custom-font(
              $--font-primary,
              $--font-weight-bold,
              $--font-size-xs,
              $--line-height-lg,
              $--letter-spacing-lg,
              $--letter-spacing-lg
            );
            color: $--color-heading;
        

      }
}

