@import "../../../styles/index.scss";
.title {
    @include title_text();
  }

  .question {
   
    @include title_question();
    margin-bottom: .8rem;
  }

  .wrapper{
    border-bottom: 1px solid #DDD;
    background: #FFF;

}
.container{
  display: flex;
  // border: 1px solid red;
  flex-direction: column;
  gap: 1rem;

}
.conditionWrap{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

 
}
.conditionWrap>div {
  width: 46%;
  // border: 1px solid green;
}

.input{
  width: 100%;
  height: 40px;border-radius: 4px;
  border: 1px solid #DDD;
  padding-left: 1rem;
  outline: none;
}
.input:hover{
  border: 1px solid #00A7B5;
}
.input:focus {
  border: 1px solid #00A7B5;
 
}



@media screen and (max-width:500px) {
  .conditionWrap{
    display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: start;
   width: 100%;
  
   
  }
  .container{

    gap: .5rem;
  
  }
  .conditionWrap>div {
    width: 100%;
    // border: 1px solid green;
  }
  
}