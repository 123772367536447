@import "../../../styles/index.scss";


.group_Btn{
    @include grid(var(--columns, 4), var(--gap, 1rem)); 
    width: 100%;
}


.radio {
    height: $--height-md;
    width: $--width-lg;
    background-color: #eee;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
    transition: background-color 0.2s;
}

.label{
    display: flex;
    align-items: center;
    justify-content: start;
    font-size: $--font-size-md;
    // border: 1px solid rgb(106, 255, 0);
    // min-width: 100%;

}

.radio:checked {
    background-color: #00A7B5 !important;
    // border: 1px solid red !important;
    color :#00A7B5
}
@media screen and (max-width:850px) {
    .group_Btn{
        // @include flex($--flex-direction-row, $--justify-content-between, $--align-items-center);
        @include grid(3,1rem);
        // border: 1px solid red;
        // gap: 1rem;
        width: 80%;
        // flex-wrap: wrap;
    }
    
}
@media screen and (max-width:850px) {
    .group_Btn{
        // @include flex($--flex-direction-row, $--justify-content-between, $--align-items-center);
        @include grid(2,1rem);
        // border: 1px solid red;
        // gap: 1rem;
        width: 80%;
        // flex-wrap: wrap;
    }
    
}

@media screen and (max-width:500px) {
    .label{
       
        font-size: $--font-size-xxxs;
        // border: 1px solid rgb(106, 255, 0);
    
    }
    .radio {
        height: $--height-xs;
        width: $--width-xs;
    }
    .group_Btn{
        // @include flex($--flex-direction-row, $--justify-content-between, $--align-items-center);
        @include grid(3,1rem);
        // border: 1px solid red;
        // gap: 1rem;
        width: 90%;
        // flex-wrap: wrap;
    }
}

@media screen and (max-width:400px) {
    .label{
       
        font-size: $--font-size-xxxs;
        // border: 1px solid rgb(106, 255, 0);
    
    }
    .radio {
        height: $--height-xs;
        width: $--width-xs;
    }
    .group_Btn{
        // @include flex($--flex-direction-row, $--justify-content-between, $--align-items-center);
        @include grid(2,1rem);
        // border: 1px solid red;
        // gap: 1rem;
        width: 90%;
        // flex-wrap: wrap;
    }
}