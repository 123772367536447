@import "../../../styles/index.scss";

.title {
    @include title_text();
}

.flex {
    display: flex;
    flex-direction: column;
    gap: 1rem;

}

.btn_flex {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
    min-width: 40%;
}
.space{
    padding-left: 0rem !important;
    padding-right: 0rem !important;
    // border: 1px solid red;
}

.btn {
    border-radius: 6px;
    background: rgba(0, 167, 181, 0.85);
    color: #FFF;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 1.32px;
    padding: 1rem 1.5rem;
    font-family: $--font-primary;
    border: 1px solid rgba(0, 167, 181, 0.85);
    cursor: pointer;
}

.btn_option {
    color: rgba(51, 51, 51, 0.80);
    font-variant-numeric: lining-nums tabular-nums;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 1.08px;
    border-radius: 6px;
    border: 1px solid #00A7B5;
    background: #FFF;
    padding: 1rem 1.5rem;
    cursor: pointer;
}

.btn_dis_select {
    border-radius: 6px;
    color: #FFF;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 1.32px;
    padding: 1rem 1.5rem;
    font-family: $--font-primary;
    border: 1px solid rgba(0, 167, 181, 0.85);
    cursor: pointer;
    border-radius: 6px;
    background: rgba(0, 167, 181, 0.40);
}

.wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.btn_option_disable {
    color: rgba(51, 51, 51, 0.80);
    opacity: .4;
    // background-color: #787171;
    font-variant-numeric: lining-nums tabular-nums;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 133.333% */
    letter-spacing: 1.08px;
    border-radius: 6px;
    border: 1px solid #00A7B5;
    // background: #FFF;
    padding: 1rem 1.5rem;
    cursor: pointer;
}


@media screen and (max-width:500px) {
    

    .btn{
        font-size: .75rem;
        padding: .5rem .5rem;
    }
    .btn_dis_select {
        font-size: .75rem;
        padding: .5rem .5rem;
    }

    .btn_option {
        font-size: .75rem;
        padding: .5rem .5rem;
    }
    .btn_option_disable {
        font-size: .75rem;
        padding: .5rem .5rem;
    }
    
}