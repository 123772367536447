
.steps {
    display: flex;
    flex-direction: column;
    gap:2rem;
    position: relative;
  }
  
  .step {
    display: flex;
    align-items: center;
    gap:1rem;
    margin-right: 20px; /* Adjust spacing between steps */
    margin-top: 1rem;
   
    
  }
  
  .indicator {
    width: 30px;
    height: 30px;
    border: 2px solid #DDD;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .indicator::before {
    content: '';
    position: absolute;
    background-color: #DDD;
    width: 18px;    
    height: 18px;
    border: 2px solid #DDD;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    // z-index: -1; 
  }

  .indicator::after {
    content: '';
    position: absolute;
    width: calc(100% - 4px); /* Adjust the width to fit inside the border */
    height: 1px;
    // background-color: green;
   
 
  }

  .line {
    position: absolute;
    width: 2px;
    height: calc(100% - 4px); 
    background-color: #DDD; /* Default color */
    top: 3%;
    left: 14px; /* Adjust left position to center the line */
    // border: 1px solid red;
    z-index: 1;
  }


   .completed .indicator::before {
    background-color: #17AB13;;
    border-color: #17AB13;
    content: '';
    position: absolute;
    // background-color: #DDD;
    width: 18px;
    height: 18px;
    // border: 2px solid #DDD;
    z-index: 2;
    border-radius: 50%;
  }
  
  .label {
    font-size: 1rem;
    font-weight: 500;
line-height: 24px; /* 150% */
letter-spacing: 0.96px;
color: rgba(51, 51, 51, 0.50)
  }

  .selected_label{
    font-size: 1rem;
  color: #17AB13;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.96px;
  }

//   .label:hover{
//     border-radius: 4px;
//     background: rgba(23, 171, 19, 0.26);
//     padding: .5rem;
//   }
  

@media screen and (max-width:500px) {
  .label {
    font-size: .75rem;

  }
  .completed .indicator::before {
 
    width: 10px;
    height: 10px;

  }
  .indicator {
    width: 18px;
    height: 18px;
   
  }
  .selected_label{
    font-size: .85rem;
  color: #17AB13;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.96px;
  }

  .indicator::before {
    content: '';
    position: absolute;
    background-color: #DDD;
    width: 9px;    
    height: 9px;
    border: 2px solid #DDD;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    // z-index: -1; 
  }
  
  .line {
    position: absolute;
    width: 2px;
    height: calc(100% - 4px); 
    background-color: #DDD; /* Default color */
    top: 3%;
    left: 8px; /* Adjust left position to center the line */
    // border: 1px solid red;
    z-index: 1;
  }
  .step{
    gap: .5rem;
  }
  .steps {
 gap: 1rem;
  }
}