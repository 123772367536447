@import "../../../styles/index.scss";

.title {
color: $--color-heading;
@include title_text();
display: flex;
align-items: center;



}
.heading_flex{
    display: inline-flex;
    gap:1rem;
  
}
.question {
@include title_question()
}

.background{
    background: rgba(210, 240, 242, 0.40);
    // border: 1px solid red;
      height:80vh !important;
}
.space{
    margin-top: .5rem;
}
.addressCard{
   
    display: grid;
    grid-template-columns: repeat(3,32.2%);
    // grid-auto-rows:1fr;
    align-items: center;
    gap: 1rem;
    width: 100%;
    // border: 1px solid rgb(0, 255, 21);
    overflow-y: scroll;
    overflow-x: hidden;
    height: 70vh;
    margin-top: 1rem;

}
.withoutScroll_Address{
    overflow-y: hidden;
    overflow-x: hidden;
    height: 100%; 
}
.addressCard::-webkit-scrollbar {
    width: 8px; /* width of the scrollbar */
}

.addressCard::-webkit-scrollbar-track {
    background: #f1f1f1; /* background of the scrollbar track */
    border-radius: 10px; /* rounded corners */
}

.addressCard::-webkit-scrollbar-thumb {
    background: $--color-primary; /* color of the scrollbar thumb */
    border-radius: 10px; /* rounded corners */
}

.addressCard::-webkit-scrollbar-thumb:hover {
    background: #555; /* color of the scrollbar thumb on hover */
}
.general_enq{
    height: 50vh; 

}

@media screen and (max-width:950px){
    .addressCard{   
        grid-template-columns: repeat(2,50%);
    }  
    
}
@media screen and (max-width:800px){
    .addressCard{   
        grid-template-columns: repeat(1,90%);
    }  
}

@media screen and (max-width:500px) {

.addressCard{   
    grid-template-columns: repeat(1,95%);
    // gap: rem;
}
}