@import "../../../styles/index.scss";


.flex{
//    border: 1px solid red;
}

.title{

@include title_text();


}
.question{
@include title_question();
margin-bottom: .9rem;

    
}

.container{
    @include flex($--flex-direction-column, $--justify-content-start, $--align-items-start);
    gap: .9rem;
}


.space{
    height: 2rem;
}

.question_space{
    margin-top: 1rem;
}

.background{
    border-bottom: 1px solid #DDD;
    background: #D2F0F2;
    // height: 190px;
}
@media screen and (max-width:500px) {
 .container{
    gap:.5rem;
 }
    
}