/* src/components/DoctorSelect.module.css */
.option {
  display: flex;
  align-items: center;
  gap: 2rem;
  border-bottom: 1px solid #DDD;
  height: 1.5rem;
  background: #FFF;
  width: 100%;

}

.option span {
  width: 40%;
}

.name {
  font-size: 1rem;
  font-weight: 400;
}

.specialty {
  font-size: 10px;
  font-weight: 400;
  background-color: #ffe0f0;
  color: #ff69b4;
  padding: 2px 8px;
  border-radius: 12px;
  width: auto !important;

}

.select_props {
  width: 100%;
  height: 40px;
}

@media screen and (max-width:500px) {
  .select_props {
    height: 30px;
    // border: 1px solid red;
  }

  .name {
    font-size: .75rem;
  
  }

}