@import "../../../styles/index.scss";

.wrapper {
    border-radius: 6px;
    border-bottom: 1px solid #DDD;
    background: #FFF;
    padding: 2rem;
}
.spacing{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.title{
    font-size: 22px;
    font-weight: 500;
}

.center{
    text-align: center;
    color: rgba(51, 51, 51, 0.70);
    font-size: 1rem;
    
}
.btn_wrap{
    cursor: pointer;
}
.input_box{
    // display: inline-flex;
    display: flex;
    gap: 2rem;
}

.input{
    width: 100%;
    height: 40px;
    border: 1px solid #DDD;
    padding: .6rem;
  outline: none;
background: #FFF;
}

.input::placeholder{
    font-size: 1rem;
}

.button{
    display: inline-flex;
    gap: 1rem;
    background: #FFF;
    font-size: 1.2rem;
    color: #00A7B5;
    font-weight: 500;
    width: auto !important;
    padding: .6rem 1rem .6rem 1rem;
    border-radius: 6px;
    border: 1px solid #00A7B5;    
}




.icon{
    display: inline-flex;
    align-items: center;
       margin: auto;
}

.doctor_filled{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.meetingLinkBtn {
    background-color: #00A7B5;
    min-height: 46px;
    border-radius: 6px;
    border: 1px solid #DDDDDD;
    color: white;
    font-size: medium;
    justify-content: left;
  
}

.meetingLinkContainer{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.doctor_det{
    display: inline-flex;
    gap: 1rem;
}

.payment_link_btn{
    display: flex;
    flex-wrap: wrap;
    gap: .3rem;
    margin-top: 1rem;

}

.link_btn{
    font-size: 1rem;
    font-weight: 500;
    color: white;
    background-color: #00A7B5;
    padding: 1.2rem;

}
.link_btn:hover{
    background-color: #00A7B5 !important;
    color: white !important;
    padding: 1.2rem;
}
@media screen and (max-width:500px) {
    .title{
        font-size: 14px;
        color:  $--color-heading;
    }
    .link_btn{
        font-size: .9rem;
        padding: 1rem;
        margin-top: .5rem;
    }
    // .payment_link_btn{
    //     flex-direction: column;
    // }
    .input{
        height: 30px;
     
    }
    .input::placeholder{
        font-size: .75rem;
    }
    .meetingLinkBtn {
        // width: 80%;
        font-size: .85rem;
        min-height: 36px !important;     
    }
    .spacing{
        gap: .9rem;
    }
    .wrapper {
        border-radius: 0;
        border-bottom: none;
        background:none;
        padding: .05rem;
    }
    .center{
            font-size: .75rem;
        
    }
    .doctor_filled{
        font-size: .75rem;
        gap: .5rem;
    }
    .doctor_det{
        gap: .75rem;
    }
    .warning{
        font-size: .75rem;
    }
    .Upload_btn{
        font-size: .75rem;
    }
 
}