@import "../../../styles/index.scss";
.wrapper{
    @include flex($--flex-direction-row, $--justify-content-between, $--align-items-center);
    gap: 1rem;
    height: 4rem;
    // padding: 0 1rem;
    // border: 1px solid red;   
    width: 100%;
  
}

