@import "../../../styles/index.scss";
.wrapper{
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .title {
    @include title_text();
  }

  .btn{
    margin-top: .5rem;
    border: none;
    padding: .5rem;
    background-color: $--color-primary;
    color: $--color-bg-white;
  }
  .btn:hover{
    color: $--color-bg-white;
  }
.not_found_text{
  font-size: 1.2rem;
}

  @media screen and (max-width:500px) {
    .wrapper{
  
      gap: .5rem;
    }

    .not_found_text{
      font-size: .75rem;
    }
  }