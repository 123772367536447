
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;   
    font-style: normal;
    // background-color: #000;
    
}
body{
    color: #000;
    font-family: 'Poppins', sans-serif;
}