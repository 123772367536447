@import "../../../styles/index.scss";

.flex {
    display: flex;
    gap: .313rem;
    align-items: center;
    background-color: rgba(211, 239, 210, 0.66);
    padding: 0.188rem 0.55rem 0.10rem .5rem;
    border-radius: .625rem;

}
.icon {
    width: 1.5rem;
    height: 1.5rem;
    background-color: rgba(23, 171, 19, 0.32);
    @include flex($--flex-direction-row, $--justify-content-center, $--align-items-center);
    border-radius: 50%;
    padding: 0.25rem;

}
.doctor_icon {
    width: .9rem;
    height: .9rem;
    background-color: rgba(23, 171, 19, 0.32);
    @include flex($--flex-direction-row, $--justify-content-center, $--align-items-center);
    border-radius: 50%;
    padding: 0.25rem;
}
.img {
    width: 0.875rem;
    height: 0.875rem;
}

.doctor_img {
    width: 0.567rem;
    height: 0.567rem;

}
.value {
    @include custom-font($--font-primary, $--font-weight-medium, $--font-size-md, $--line-height-xxl, $--letter-spacing-lg, $--letter-spacing-lg);
    color: #333;
    font-variant-numeric: lining-nums tabular-nums;
}
.poc_Card {
    @include flex($--flex-direction-row, $--justify-content-between, $--align-items-center);
    // border: 2px solid;
    cursor: pointer;

}

.doctorCard {
    font-size: 11.394px;
    font-weight: 500;

}


@media screen and (max-width:"500px") {
    .label {
        font-size: .75rem;
        font-weight: 600;
        color: rgba(51, 51, 51, 0.70);
        font-variant-numeric: lining-nums tabular-nums;
    }
    .value {
        font-size: .75rem;
        font-weight: 600;
        letter-spacing: 0.02rem;
        color: #333;
        font-variant-numeric: lining-nums tabular-nums;
    }
    .img {
        width: 0.575rem;
        height: 0.575rem;
    }
    .edit_img{
        width: 0.975rem;
        height: 0.975rem;
    }

}