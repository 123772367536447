@import "../../../styles/index.scss";

.background {
    background: rgba(210, 240, 242, 0.40);
    height:100%;
}

.title {
    @include title_text();
    color: $--color-heading;
}

.question {
    @include title_question()
}
.space{
    margin-top: .75rem;
    line-height:1.5rem
}
.spaceB{
    margin-Bottom: .75rem
}

.address_title {
    @include custom-font($--font-primary, $--font-weight-regular, $--font-size-lg, $--line-height-lg, $--letter-spacing-lg, $--letter-spacing-lg);
    color: rgba(51, 51, 51, 0.70);
};
.address_wrapper{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.card{
    border-radius: 6px;
    border: 1px solid #DDD;   
    color: white; 
    background:#00A7B5;  
    cursor: pointer;
    display: inline-flex;
    padding: .5rem;
    margin-top: 1rem;
    
}
.icon_prop{
    color: white !important; 

}

.icon{  
    display: inline-flex;
    align-items: center;
    gap: .3rem;
}

.addressCard{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 1rem;
    width: 100%;
}


@media screen and (max-width:800px) {
    .addressCard{
        grid-template-columns: repeat(2,1fr);
     }
    
}

@media screen and (max-width:600px){
    .addressCard{
           grid-template-columns: repeat(1,1fr);
    } 
    .btn{
        font-size: $--font-size-xs;
    
    }
    .address_title {
        font-size: $--font-size-xs;
        color:  #385BF0;
    };
}