@import "../../styles/index.scss";


.ticketDet__container{
    padding-top: 1.75rem;
    padding-left: 5.188rem;
}

.border{
    width: 100%;
    @include border(0.125rem, $--border-style-solid, $--color-heading);
}

.wrapper{
    // border: 5px solid red;
    @include padding(0,$--padding-lg,0,$--padding-lg);
  

}
.heading{
   @include heading();
}
.container{
     background: rgba(210, 240, 242, 0.32);
     margin-top: 1rem;
     height: 100%;
     padding-bottom: 8rem
     
}
.assessment_container{
    // border: 1px solid rgb(46, 200, 32);
    @include padding($--padding-sm,$--padding-xxxxxl,0,$--padding-xxxxxl); 
    display: flex;
    flex-direction: column;
    gap:2rem ;
 
}
.step{
    display: flex;
    justify-content: flex-end;
}
.step_button{
    color: $--color-bg-progress;
    background: rgba(72, 114, 213, 0.24);
    border-radius: 0.9rem;
    @include custom-font($--font-primary, $--font-weight-regular, $--font-size-sm, $--line-height-lg, 0, $--letter-spacing-lg);
    @include padding(0.375rem,$--padding-lg,0.375rem,$--padding-lg);
    border: 1px solid $--color-bg-progress;

}

.emHandle{
    // margin-top: 2.5rem;
    // border: 1px solid red;
    // height:100vh 
}

.button{
    float: right;
    background-color: $--color-button;
    @include custom-font($--font-primary, $--font-weight-medium, $--font-size-xl, $--line-height-xxl, $--letter-spacing-lg, $--letter-spacing-lg);
    font-variant-numeric: lining-nums tabular-nums;
    @include padding($--padding-lg ,$--padding-xxxxxl,$--padding-lg,$--padding-xxxxxl);
    color:$--color-bg-white
}
.button:hover{
    color:$--color-bg-white
}

@media screen and (max-width:500px) {
    .heading{
        @include custom-font($--font-primary, $--font-weight-bold, $--font-size-xs, $--line-height-lg, $--letter-spacing-lg, $--letter-spacing-lg);
        color: $--color-heading;
    }
    .step{
        display: none;
    }  
    .wrapper{
        padding: 0;
    } 

    .assessment_container{
        // border: 1px solid rgb(46, 200, 32);
        @include padding($--padding-xxs,$--padding-xxs,0,$--padding-xxs); 
        
        gap:1rem ;
     
    }

    .button{
        float: left;
        background-color: $--color-button;
        @include custom-font($--font-primary, $--font-weight-medium, $--font-size-xxs, $--line-height-xxl, $--letter-spacing-lg, $--letter-spacing-lg);
        font-variant-numeric: lining-nums tabular-nums;
        @include padding($--padding-xxs ,$--padding-xxs,$--padding-xxs,$--padding-xxs);
        color:$--color-bg-white
    }
}