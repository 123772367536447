
@import "../../../styles/index.scss";


.title{
    @include title_text();
    color:$--color-heading
    
    }
.background{
    background: rgba(210, 240, 242, 0.40);
    height: 100%;
}
.wrapper{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
}

.edit_icon{
    position: absolute;
    right: 1rem;
    // top: 1rem;
    cursor: pointer;
}

@media screen and (max-width:500px) {
    .edit_icon{
        font-size: $--font-size-xs;
    }
}