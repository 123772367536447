@import "../../../styles/index.scss";
.list{
    gap: .85rem;
    height: 4rem;
    // padding: 0 1rem;
    // width: 40vw;
    // border: 1px solid red;
    // @include backGround($--color-bg-hamburger);
    // @include border($--size-xxs, $--border-style-solid, $--color-bg-white);
    color: $--color-bg-white;
    @include cursor-pointer();
    @include custom-font($--font-primary, $--font-weight-medium, $--font-size-md, $--line-height-lg, $--letter-spacing-lg, $--letter-spacing-lg);
  }
  .wrapper{
    // @include backGround($--color-primary);
    // height: 100vh;
    // width: 100%;
    // border: 1px solid red;

  }

  @media screen and (max-width: 768px) {
   
    .list p{
    display: none;
      
    }
    
  }