@import "../../../styles/index.scss";


.wrapper{
    border-bottom: 1px solid #DDD;
    background: rgba(253, 222, 239, 0.34);
}

.title {
    @include title_text();
  }

  .question {
    @include title_question();
  }