@import "../../styles/index.scss";

.logistics_wrapper {
  @include padding(0, $--padding-lg, 0, $--padding-lg);
  margin-top: 1.5rem;
  // border: 1px solid red;
  
}

;

.heading {
  @include heading();
  margin-bottom: 2rem
  
}
.tab_heading{
  @include heading();
  background: rgba(56, 91, 240, 0.30);
  padding: .9rem
}
;

.heading_operation {
  color: rgba(51, 51, 51, 0.7) !important;
  @include heading();
  cursor: pointer;
  
}

.border {
  width: 100%;
  @include border(0.125rem, $--border-style-solid, $--color-heading);
  margin-top: .5rem
}

.flex {
  height: 1.8rem;
  //  border: 1px solid red;
}

.operation_wrapper {
  // border: 1px solid red;
  cursor: pointer;
  width: 50%;
}

.background {
  margin-top: 1.5rem;
  background: rgba(210, 240, 242, 0.32);
  height: auto;
}

.wrapper {
  display: flex;
  // flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
  // align-items: center;
  height: 100%;
  // border: 1px solid red;
  width: 100%;

  &.single {
    justify-content: center; /* Center if there's only one component */
  }
}



.doctor_component {
  width: 50%;
  // border: 1px solid red;
}

.ambulance_component {
  width: 50%;
  // border: 1px solid red;
 
}

.button {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: $--font-size-xl;
  font-weight: $--font-weight-medium;
  color: $--color-bg-white;
  padding: 1rem 1.5rem;
  background: $--color-button;
  line-height: 24px;
  /* 109.091% */
  letter-spacing: 1.32px;
  border-radius: .5rem;
  border: 1px solid $--color-button;
  // width:auto !important;
}

.is_disabled{
 @include disable_btn()
}

.step{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}
.step_button{
  color: $--color-bg-progress;
  background: rgba(72, 114, 213, 0.24);
  border-radius: 0.9rem;
  @include custom-font($--font-primary, $--font-weight-regular, $--font-size-sm, $--line-height-lg, 0, $--letter-spacing-lg);
  @include padding(0.375rem,$--padding-lg,0.375rem,$--padding-lg);
  border: 1px solid $--color-bg-progress;

}

@media screen and (max-width:950px) {
  .wrapper {
    flex-direction: column;
  }
  .doctor_component, .ambulance_component  {
    width: 100%;
    // height: 100%;
  
    // border: 1px solid red;
  
  }
}


@media screen and (max-width:500px) {
  .logistics_wrapper {
    @include padding(0, 0,.5rem,0);
    margin-top: .8rem;
  }
  .wrapper {
    flex-direction: column;
  }
  .doctor_component, .ambulance_component  {
    width: 100%;
    // height: 100%;
    padding: .8rem;
    background-color: #EDF9FA;;
    // border: 1px solid red;
  
  }
  .step_button{
       font-size: $--font-size-xs;
       @include padding(0.175rem,$--padding-sm,0.175rem,$--padding-sm);
  
  }
  .heading{
    display: none;
  }
 
  .background{
    // background-color: white;
    padding: 0rem !important;
    // border: 1px solid rgb(47, 255, 0);
  }
  .button {
    font-size: $--font-size-xxs;
    padding: .5rem 1.5rem; 
    border-radius: 6px; 
  }
  .is_disabled{
    @include disable_btn();
    font-size: $--font-size-xxs;
    padding: .5rem 1.5rem; 
    border-radius: 6px; 
   }
  
}