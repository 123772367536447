@import "../../../styles/index.scss";



.wrapper {
  width: 100%;
  height: calc(100vh - 5.5rem);
  position: fixed;
  top: 5.5rem;
  @include backGround($--color-bg-white);
  @include border($--size-xxs, $--border-style-solid, $--color-bg-white);
  // color: $--color-bg-white;
  // background-color: red;
  // z-index: -1;

}

.left_side {
  width: 16.67%;
  float: left;
  height: 100%;
  @include padding(0, 0, 0, $--padding-sm);
  @include backGround($--color-primary);
  // overflow-y: hidden;
}

.main_content {
  width: 81.33%;
  // border: 1px solid rgb(100, 151, 68);
  float: right;
  @include backGround($--color-bg-white);
  overflow-y: scroll;
  height: 100%;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  
  position: relative;
  overflow-x:hidden;
}
.floating_button {
  position: fixed;
  right: 2rem;
  bottom: 2rem; 
  z-index: 100; 
  padding: 1rem;
  background-color: $--color-primary;
  color: $--color-bg-white;
  font-weight: 500;
  font-size: 1.25rem;
  border-radius: 1rem;
  border: 1px solid $--color-primary;
  cursor: pointer;
}

.main_content_width {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .left_side {
    width: 10.67%;

  }
  .main_content {
    width: 86.33%;
  }
}

@media screen and (max-width:525px){
  .wrapper {
   top: 3.8rem;
  
  }
  .left_side {
    display: none;
   
    
    // overflow-y: hidden;
  }


  .floating_button {    
    padding: .8rem;
    font-weight: 500;
    font-size: .75rem;
  }

  .main_content {
    width: 100%;
    @include padding(0rem, 1rem, 1rem, $--padding-sm);
  }
  
}