
@import "../../../styles/index.scss";
.heading {
   @include heading();
}
.container{
    display: flex;
    flex-direction: column;
    gap: .5rem;
}
.fontStyles{
    color: #333;
    font-variant-numeric: lining-nums tabular-nums;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Poppins",;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 120% */
    letter-spacing: 1.2px;
}

.btn_wrap{
//   border: 1px solid red;
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width:500px) {
  .background{
    background: #EDF9FA;
 
  }
  .fontStyles{
    font-size: 12px;
  }

  
}