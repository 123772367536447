
@import "../../../styles/index.scss";

.title{
    @include custom-font($--font-primary, $--font-weight-medium, $--font-size-xl, $--line-height-xxl, $--letter-spacing-lg, $--letter-spacing-lg);
    margin-bottom: .5rem;
   
}
.color{
    color: $--color-heading;
    font-weight: 600;
}


// .ticket_card{
//     display: grid;
//     grid-template-columns:50% 0px 70%;
//     border: 1px solid red; 
//     gap: 0.625rem;
//     margin-bottom: 0.5rem;
// }

// .ticket_card2{
//     display: grid;
//     grid-template-columns:15% 0px 50%;
//     // border: 1px solid red; 
//     gap: 0.625rem;
//     margin-bottom: 0.5rem;
// }
.ticket_card,
.ticket_card2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem; 
  justify-content: stretch;
  margin-bottom: 0.5rem;
//    border: 1px solid rgb(108, 196, 44); 
//    width: 0%;
}

.label{
    @include custom-font($--font-primary,$--font-weight-medium,$--font-size-md,$--line-height-xxl,$--letter-spacing-lg,$--letter-spacing-lg);
    color: rgba(51, 51, 51, 0.70);
    font-variant-numeric: lining-nums tabular-nums;
}

.value{
    @include custom-font($--font-primary,$--font-weight-medium,$--font-size-md,$--line-height-xxl,$--letter-spacing-lg,$--letter-spacing-lg);
    color:  #333;
    font-variant-numeric: lining-nums tabular-nums;
    word-wrap: break-word;
} 
.flex{
    display: flex;
    gap: .313rem;
    align-items: center;
    background-color:  rgba(211, 239, 210, 0.66);
    padding: 0.188rem 0.55rem 0.10rem  .5rem;
    border-radius: .625rem;
}

.icon{

 width: 1.5rem;
 height: 1.5rem;
 background-color: rgba(23, 171, 19, 0.32);
 @include flex($--flex-direction-row, $--justify-content-center, $--align-items-center);
 border-radius: 50%;
 padding: 0.25rem;
    
}

.img{
    width: 0.875rem;
    height: 0.875rem;
}

.cardWrapper{

    // border: 1px solid red;
    max-width: 90%;
    padding: .2rem 0;
    @include flex($--flex-direction-row, $--justify-content-between, $--align-items-start);
}
.card{
    width: 50%;
    // border: 1px solid rgb(0, 26, 255);
}

.card_key{
    display:flex;
    min-width:40%;
    justify-content: flex-start;
    // border: 1px solid red;

}
.card_value{
    display: flex;
    justify-content: flex-start;
    min-width:50%;
}
.space{
    display: inline-flex;
    align-items: center;
    margin-right: .3rem;
}
.assessment_component{
    display: flex;
    justify-content: flex-start;
    // width: 100%;
}

.head_flex {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
//   height: 1.8rem;
   margin-right: 1rem;
  
   background-color: none !important;
  }
  
.border{
    @include border(0.125rem, $--border-style-solid, $--color-heading);
    margin: .1rem 1.1rem .8rem 0rem;
    cursor: pointer;

}
@media screen and (max-width:"1130px") {
    .cardWrapper{
        width: 100%;
        padding: 1rem 0;
    };
}

@media screen and (max-width:"770px") {

    .card{
        width: 100%;
        // border: 1px solid rgb(0, 26, 255);
    };

    .cardWrapper{
        // border: 1px solid red;
        width: 90%;
        padding: 1.2rem 0;
        @include flex($--flex-direction-column, $--justify-content-between, $--align-items-start);
    };

    .label{
        @include custom-font($--font-primary,$--font-weight-medium,$--font-size-md,$--line-height-xxl,$--letter-spacing-lg,$--letter-spacing-lg);
        color: rgba(51, 51, 51, 0.70);
        font-variant-numeric: lining-nums tabular-nums;
    };
 
}


@media screen and (max-width:"500px") {

    .card{
        width: 100%;
        // border: 1px solid rgb(0, 26, 255);
    }

    .cardWrapper{
        // border: 1px solid red;
        // border: 1px solid #DDD;
        width: 100%;
        padding: 1.2rem 0;
        padding-bottom: .2rem;
        @include flex($--flex-direction-column, $--justify-content-between, $--align-items-start);
    }

    .label{
        font-size: .75rem;
        font-weight: 600;
        color: rgba(51, 51, 51, 0.70);
        font-variant-numeric: lining-nums tabular-nums;
    }

    .value{
        font-size: .75rem;
        font-weight: 600;
        color:  #333;
        font-variant-numeric: lining-nums tabular-nums;
        width: auto !important;
        word-wrap: break-word;
        min-width: 50%;
        // border: 1px solid red;
    }
    .title{
       font-size: medium;
       font-weight: 600;
       color: $--color-heading;
    }
}
