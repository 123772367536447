@import "../../../styles/";

.wrapper {
    border-radius: 6px;
    border-bottom: 1px solid #DDD;
    background: #FFF;
    padding: 2rem;
}

.spacing {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    // border: 1px solid red;
}

.title {
    font-size: 22px;
    font-weight: 500;
}
.is_disabled{
    @include disable_btn()
   }
.button {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: $--font-size-xl;
    font-weight: $--font-weight-medium;
    color: $--color-bg-white;
    padding: 1rem 1.5rem;
    background: $--color-button;
    line-height: 24px;
    /* 109.091% */
    letter-spacing: 1.32px;
    // width:auto !important;
    border-radius: .5rem;
    border: 1px solid $--color-button;
}


.button:hover {
    color: $--color-bg-white;
}
.sub_title{
    display: none;
}

.link_btn{
    font-size: 1rem;
    font-weight: 500;
    color: white;
    background-color: #00A7B5;
    padding: 1.2rem;

}
@media screen and (max-width:560px) {
    .title{
        font-size: 14px;
        color:#385BF0;
        font-weight: 600;
        
    }
    .link_btn{
        font-size: .9rem;
        padding: 1rem;
        margin-top: .5rem;
    }
    .sub_title{
        display: block;
        color:#385BF0;
        font-weight: 400;
        font-size: 14px;
    }
    .spacing{
        gap: .5rem;
    }
    .wrapper{
        padding: 0rem;
        border-radius: 0px;
        border-bottom: none;
        background:none;
    }
    .button {
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-size: .75rem;
        font-weight: $--font-weight-medium;
        padding: .4rem 1.5rem;
        border-radius: 6px;
      
    }
    .is_disabled{
        @include disable_btn();
        font-size: .75rem;
        padding: .4rem 1.5rem;
        border-radius: 6px; 
       }
    
}