@import "../../../styles/";

.border {
  width: 100%;
  @include border(0.125rem, $--border-style-solid, $--color-heading);
}

.wrapper {
  // border: 5px solid red;
  @include padding(0, $--padding-lg, 0, $--padding-lg);
  // display: flex
  // width: 100%;
}
.logistics_wrapper {
  @include padding(0, $--padding-lg, 0, $--padding-lg);
  margin-top: 1.5rem;
  
}
.heading {
 @include heading();

}
.heading_operation {
  @include heading();
  color: rgba(51, 51, 51, 0.7);
}
.icon_wrapper {
  // border: 1px solid red;
  cursor: pointer;
  //  width: 50%;
  // display: inline-flex;
}
.medical_list{
  width: 85%;
  flex-wrap: wrap;
  gap:.5rem;
}

.operation_wrapper {
  // border: 1px solid red;
  cursor: pointer;
  width: 50%;
}

.flex {
  height: 1.8rem;
  //  border: 1px solid red;
}

.container {
  // background: rgba(210, 240, 242, 0.32);
  margin-top: 1rem;
  // height: 100%;
  // padding-bottom: 3rem
}
.logistics_container {
  //  height: 100%;
  transition: opacity 0.5s ease-in-out;
}

.assessment_container {
  // border: 1px solid rgb(46, 200, 32);
  @include padding($--padding-sm, $--padding-xxxxxl, 0, $--padding-xxxxxl);
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.logistics_box {
  @include padding($--padding-sm, $--padding-xxxxxl, 0,$--padding-xxxxxl);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  // border: 1px solid red;
}
.border_inner {
  border-bottom: 1px solid #ddd;
  background: #fff;
}

.title {
  @include custom-font(
    $--font-primary,
    $--font-weight-medium,
    $--font-size-xl,
    $--line-height-xxl,
    $--letter-spacing-lg,
    $--letter-spacing-lg
  );
}
.medical_wrapper {
  display: flex;
  flex-direction: column;
  // gap: .5rem;

}

.ans {
  margin-top: 1rem;
  @include custom-font(
    $--font-primary,
    $--font-weight-regular,
    $--font-size-lg,
    $--line-height-lg,
    $--letter-spacing-lg,
    $--letter-spacing-lg
  );
  color: #333333;
  font-style: normal;
}
.patient_condition {
  display: flex;
  width: 90%;
  flex-direction: row;
  justify-content: space-between;
  // gap: 1rem;
}
.button {
  font-size: $--font-size-md;
  font-weight: $--font-weight-medium;
  color: $--color-bg-white;
  padding: 1.125rem;
}

.button:hover {
  color: $--color-bg-white;
}

.assistance_res{
  display: flex;
}
@media screen and (max-width:500px) {
  .assessment_container {
    width: 100%;
    padding-left: 1rem;
   
  }

  .title{
    font-size: medium;
    font-weight: 600;
 }
 .medical_list{
  flex-wrap:nowrap;
  flex-direction: column;
  gap:0.2rem;
  justify-content: flex-start;
  align-items: flex-start;
 }
.patient_condition{
  flex-direction: column;
  width: 100%;
  gap: .2rem;
}
.assistance_res{
  display: flex;
  flex-direction: column;
}

.ans {
  margin-top: .2rem;
 font-size: $--font-size-xxs;
}

.wrapper {
padding-left: 0rem;
padding-right: 0rem;
}
}
