
.wrapper{
    display: grid; 
    grid-template-columns: 50% 50%;
    align-items: center;
    width: 100%;
    height: 3rem;
    
}

@media screen and (max-width:500px) {
    .wrapper{
        height: 2.2rem;
    }
    
}

@media screen and (max-width:350px) {
    .wrapper{
        height: 3.8rem;
    }
    
}