.wrapper{
    // width: 10% !important;
    border-bottom: 1px solid rgb(214, 224, 213);
    margin-bottom: 2rem;
    margin-top: 1rem;
    // display: flex;
    // gap: rem;
    // background-color: aliceblue;
}
.key,.value{
    // flex: 1;
    display: flex;
    flex-direction: column;
    gap:1rem;   
}
.card_wrapper{
   display: flex; 
//    flex-direction: row;
//    border: 1px solid red;
//    gap:1rem;

}

@media screen and (max-width:500px) {

    .key,.value{
     
        gap:.5rem;   
    } 
}