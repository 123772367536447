@import "../../../styles/index.scss";
.heading {
   @include heading()
}



.input{
    width: 100%;
    height: 100px;
    padding: 1rem;
    border-radius: 6px;
 background: rgba(253, 222, 239, 0.40);
  border: none;
  outline: none;
//   resize: vertical;
  box-sizing: border-box;
  overflow: auto;
  font-size: 1.25rem;
}

.title{
  color: #061DB2;
font-variant-numeric: lining-nums tabular-nums;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 120% */
letter-spacing: 1.2px;
}
.space{
  display: flex;
  flex-direction: column;
  gap: 1rem;
};
.button{
  background-color: #061DB2;
  color: $--color-bg-white;
  font-size: 1.125rem;
  padding: 0.688rem 2.3185rem;
}
.button:hover{
  color: $--color-bg-white;

}
.btn_wrapper{
  display: flex;
  justify-content: flex-end;
}
.agentNotes{
  color: #333;
font-variant-numeric: lining-nums tabular-nums;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 150% */
letter-spacing: 0.96px;
}
.space_vert{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media screen and (max-width:500px) {
  .input{
    font-size: .85rem;
  }
  .background{
    background: #EDF9FA;
 
  }
  .title{
    font-size: 12px;
  }
  .no_data{
    font-size: 12px;
  }

  .button{
   font-size: 14px;
   padding: 0.344rem 1.125rem;
  }
  
}
