@import "../../../styles/index.scss";

.card{
    border-radius: 6px;
    border: 1px solid #DDD;    
    background: #FFF;  
    // border: 1px solid red;
    // display: inline-flex;
    width:90%;
    padding: .5rem;
    height: 100%; 
    flex-grow: 1;
    // display: flex;
    // flex-direction: column;
      
}
.card_border{
    border-radius: 6px;
    border: 1px solid #DDD;    
    // background: #FFF;  
    // border: 1px solid red;
    // display: inline-flex;
    width:90%;
    padding: .5rem;
    // min-height: 300px;
    background: #FDDEEF;   
    height: 100%;
    box-sizing: border-box; 
    flex-grow: 1;
}

.grid_wrapper{
    // border: 1px solid red;
}
.inline_block{
    display: inline-block;
}
.radio{
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
    transition: background-color 0.2s;
}
.card_flex{
    display: flex;
    // justify-content: space-between;
    // margin-top: 1rem;
    // flex-grow: 1;
    height: 100% !important;
  
}

@media screen and (max-width:950px) {
    .radio_btn{
        display: none;
    }
}


@media screen and (max-width:500px) {

  
    .card_flex{
        display: inline-block;
        width: 100%;
    }
   
    
}