.ticketDet__container {
    // border: 1px solid rgb(0, 255, 26);
    padding-top: 1.75rem;
    padding-left: 1.25rem;
}


@media screen and (max-width:950px) {

    .ticketDet__container {

        padding-top: 1.75rem;
        padding-left: 1.188rem;
    }

}

@media screen and (max-width:500px) {

    .ticketDet__container {
        width: 100%;
        border: 1px solid #DDD;
        margin: .5rem 0;
        
        
    }

}