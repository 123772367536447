@import "../../styles/index.scss";

.border {
  width: 100%;
  @include border(0.125rem, $--border-style-solid, $--color-heading);
  margin-top: .5rem
}

.wrapper {
  // border: 5px solid red;
  @include padding(0, $--padding-lg, 0, $--padding-lg);
  // display: flex
  // width: 100%;
}
.logistics_wrapper {
  @include padding(0, $--padding-lg, 0, $--padding-lg);
  margin-top: 1.5rem;
 
  
}
// 385BfO
.heading {
  @include heading();
  color: $--color-heading;
  cursor: pointer;
  background: rgba(56, 91, 240, 0.30);
  padding: .9rem
};
.heading_operation {
 @include heading();
 font-size: 20px;

  color: rgba(51, 51, 51, 0.7);
  cursor: pointer;
  // border: 1px solid red;
}
.icon_wrapper {
  // border: 1px solid red;
  cursor: pointer;
  //  width: 50%;
  // display: inline-flex;
}

.operation_wrapper {
  // border: 1px solid red;

  cursor: pointer;
  width: 50%;
}

.flex {
  height: 1.8rem;
  //  border: 1px solid red;
}

.container {
  // background: rgba(210, 240, 242, 0.32);
  margin-top: 1rem;
  // height: 100%;
  // padding-bottom: 3rem
}
.logistics_container {
  //  height: 100%;
  // display: flex;
  // justify-content: space-between;
  transition: opacity 0.5s ease-in-out;
}

.assessment_container {
  // border: 1px solid rgb(46, 200, 32);
  @include padding($--padding-sm, $--padding-xxxxxl, 0, $--padding-xxxxxl);
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.logistics_box {
  @include padding($--padding-sm, $--padding-xxxxxl, 0,$--padding-xxxxxl);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  // border: 1px solid red;
}
.border_inner {
  border-bottom: 1px solid #ddd;
  background: #fff;
}
.background{
  background-color: rgba(210, 240, 242, 0.40) !important;
  height: 100%;
  // border: 1px solid red;
}

.title {
  @include custom-font(
    $--font-primary,
    $--font-weight-medium,
    $--font-size-xl,
    $--line-height-xxl,
    $--letter-spacing-lg,
    $--letter-spacing-lg
  );
}
.medical_wrapper {
  display: flex;
  flex-direction: column;
  // gap: .5rem;
}

.ans {
  margin-top: 1rem;
  @include custom-font(
    $--font-primary,
    $--font-weight-semibold,
    $--font-size-lg,
    $--line-height-lg,
    $--letter-spacing-lg,
    $--letter-spacing-lg
  );
  color: #333333;
  font-style: normal;
}
.patient_condition {
  display: flex;
  width: 90%;
  flex-direction: row;
  justify-content: space-between;
  // gap: 1rem;
}
.button {
  font-size: $--font-size-md;
  font-weight: $--font-weight-medium;
  color: $--color-bg-white;
  padding: 1.125rem;
  background-color: $--color-button;
  border: none;
}

.button:hover {
  color: $--color-bg-white;
}


@media screen and (max-width:500px) {
  .heading_operation {
    @include heading();
     color: rgba(51, 51, 51, 0.7) !important;
     cursor: pointer;

    //  border: 1px solid red;
   }
  .logistics_wrapper {
    @include padding(0,0, 0, 0);
    margin-top: 1rem;
   
    
  }
  .logistics_box {
    @include padding(1rem,0,0,0);
   
  }
  .button {
    font-size: $--font-size-xxs;
    font-weight: $--font-weight-medium;
    color: $--color-bg-white;
    padding: .8rem;
    background-color: $--color-button;
    border: none;
  }
  
}
