
@import "../../../styles/index.scss";
.heading {
  @include heading();
}


.flex{
    display: flex;
    justify-content: space-between;
    // border: 1px solid red;
}
.card_left{
    display: flex;
    gap: 1rem;
}
.title{
    color: #333;
font-variant-numeric: lining-nums tabular-nums;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 120% */
letter-spacing: 1.2px;
}
.callLog_wrapper{
    display: flex;
    flex-direction: column;
    gap: 1rem
}
.card_item{
    // border: 1px solid #763535;
    // overflow: hidden;
    border-radius: 6px;
    background: #FFF;
    box-shadow: 4px 4px 30px 0px rgba(0, 0, 0, 0.25);
    
};
.card_item_collapsed {
    max-height: 0;
};
.card_item_expanded {
    max-height: 500px; // Adjust this value based on your content's expected maximum height
  }

  
@media screen and (max-width:500px) {
    .background{
      background: #EDF9FA;
   
    }
    .title{
      font-size: 12px;
    }
  
    
  }