.horizon_wrapper {
    // border: 1px solid red;
    display: flex;
    // gap: 1.5rem;
    width: 100%;
}

.address_card2 {
    width: 100%;
    // flex: 1;
    //    width: auto !important;
    // border: 1px solid green;
}

.address_card1 {
    min-width: 40% !important;
    // border: 1px solid green;
}

.title {
    font-size: 1rem;
    font-weight: 400;
    width: 90%;
    // border: 1px solid green;

}

@media screen and (max-width:500px) {
    .address_card1 {
        display: none;
    }

    .horizon_wrapper {
        border-radius: 6px;
        border: 1px solid #00A7B5;
        background: #FFF;
        padding: .75rem;
    }
}