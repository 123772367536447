
.button {
    display: inline-flex;
    gap: 1rem;
    background: #FFF;
    font-size: 1.2rem;
    color: #00A7B5;
    font-weight: 500;
    width: auto !important;
    padding: .6rem 1rem .6rem 1rem;
    border-radius: 6px;
    border: 1px solid #00A7B5;
    cursor: pointer;
  }

  @media screen and (max-width:500px) {
    .button{
      gap: .75rem;
       font-size: .75em;
        color: #00A7B5;
        padding: .375rem .875rem .375rem .875rem;
        // border: 1px solid red;
 
    }  
    .icon{
      width: 1.125rem;
      height: 1.125rem;
    }  
  }