.loginContainer {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f0f2f5;
    // border: 1px solid red;
    // width: 100%;
  }
  
  .loginForm {
    // border: 1px solid rgb(0, 255, 8);
    width: 100%;
    padding: 1.5rem;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .loginButton {
    width: 100%;
  }
  
  /* Media Queries */
  @media (max-width: 576px) {
    .loginForm {
      padding: 16px;
    }
  
    .loginButton {
      font-size: 14px;
    }
  }
  
  @media (min-width: 576px) and (max-width: 768px) {
    .loginForm {
      padding: 20px;
    }
  
    .loginButton {
      font-size: 15px;
    }
  }
  
  @media (min-width: 768px) and (max-width: 992px) {
    .loginForm {
      padding: 22px;
    }
  
    .loginButton {
      font-size: 16px;
    }
  }
  