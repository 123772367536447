.error-boundary {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    text-align: center;
  }
  
  .error-boundary__graphic img {
    max-width: 300px;
    margin-bottom: 20px;
  }
  
  .error-boundary h1 {
    font-size: 2em;
    margin-bottom: 10px;
  }
  
  .error-boundary p {
    font-size: 1.2em;
    margin-bottom: 20px;
  }
  
  .error-boundary details {
    margin-top: 20px;
    font-size: 0.9em;
    color: #555;
  }
  