/* SliderWithLabels.css */
.slider-container {
    min-width: 100%;
    text-align: center;
    padding: 20px;
}

    
.ant-slider-mark-text {
    display: none !important;
    font-size: 16px;
    color: #000;
    width: 100%;
}

.ant-slider-mark-text:first-child,
.ant-slider-mark-text:last-child {
    display: inline-block !important;
}
.ant-slider-mark {
    top: 25px !important;
    /* Adjusted to move labels below the slider */
}

.ant-slider-rail {
    /* height: 8px; */
    height: 10px !important;
    border-radius: 4px !important;
    background: rgba(56, 91, 240, 0.39) !important;
}

.ant-slider-track {
    height: 10px !important;
    border-radius: 4px;
    background: #F0389C !important;
}

.ant-slider-handle {
    margin-top: -2px !important;
}
.ant-slider-handle::after {
    margin-top: -1px !important;
    background-color: #385BF0 !important;
    width: 20px !important;
    height: 20px !important;
}

.ant-slider-dot {
    width: 0px !important;
    height: 0px !important;
    background-color: #ba98ab !important;

}

@media screen and (max-width:500px){
    .ant-slider-mark {
        top: 1.5rem !important;
        /* Adjusted to move labels below the slider */
    } 
   
    
    .ant-slider-track {
        height: 6px !important;
        border-radius: 3px;
 
    }

    .ant-slider-rail {
        /* height: 8px; */
        height: 6px !important;
     
    }

    .ant-slider-handle {
        margin-top: -.3px !important;
    }
    .ant-slider-handle::after {
        width: 12px !important;
        height: 12px !important;
    }
    .ant-slider-mark-text {
        font-size: 10px;
       
    }
    .ant-slider-mark {
        top: 20px !important;
        /* Adjusted to move labels below the slider */
    }
}
