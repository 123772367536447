@import "../../../styles/index.scss";

.wrapper{
    width: 100%;
    height: 5.5rem;
    @include backGround($--color-bg-white);
    @include flex($--flex-direction-row, $--justify-content-between, $--align-items-center);
    @include border($--size-xxs, $--border-style-solid, $--color-primary);
    position: fixed;
    top: 0;
    z-index: 20;
    
    padding: 0 1rem;
}
.icon{
    width: 100%;
    // @include flex($--flex-direction-row, $--justify-content-center, $--align-items-center);

}
.logo{
    width:9.1vw;
    cursor: pointer;
    // @include border($--size-xxs, $--border-style-solid, $--color-primary);
}
.nav_left{
    height: 100%;
    // border: 1px solid red;
    @include flex($--flex-direction-row, $--justify-content-start, $--align-items-center);

}
.notification_icon{
    width: 2.2vw;
    height: 36.36%;
    // width:32px;
    // height:32px;
    // border: 1px solid red;
    @include flex($--flex-direction-row, $--justify-content-center, $--align-items-center);
}
.nav_right{
    @include flex($--flex-direction-row, $--justify-content-start   , $--align-items-center);
    gap: 2.5vw;
    height: 100%;
    // border: 2px solid red;
}
.user_profile{
    width: 3.3rem;
    height: 40.66%;
//   border: 1px solid red;
  @include flex($--flex-direction-row, $--justify-content-start   , $--align-items-center);

  img{
    width: 100%;
  }
   
}

.agent_name{
    @include text($--font-size-lg, $--text-align-center,$--font-weight-semibold,$--color-text-primary);
}

.hamburger_icon {
    display: none; // Hide by default
  }

  .list{
    gap: .85rem;
    height: 3.5rem;
    padding: 0 1rem;
    width: 40vw;
    // border: 1px solid red;
    background: rgb(8, 8, 8);
    // @include backGround($--color-bg-hamburger);
    // @include border($--size-xxs, $--border-style-solid, $--color-bg-white);
    color: $--color-bg-white;
    @include cursor-pointer();
    @include custom-font($--font-primary, $--font-weight-medium, $--font-size-lg, $--line-height-lg, $--letter-spacing-lg, $--letter-spacing-lg);
    // z-index: 10;
    z-index: 2;
    // top: 80px;
  }
  .menu{
    display: none;
  }
  .role{
    @include text($--font-size-sm, $--text-align-center,$--font-weight-semibold,$--color-bg-light-gray);
  }

@media screen and (max-width: 768px) {
    .wrapper{
        padding: 0 0.5rem;
    }
    .hamburger_icon {
      font-size: 2rem; // Hide by default
      }
    .nav_right{
     
    }
    .hamburger_icon {
        display: block; // Hide by default
      }

      .notification_icon{
       display: none;
    }
 
    .menu{
      @include flex($--flex-direction-column, $--justify-content-end   , $--align-items-end);
      // border: 1px solid red;
      margin-top: 60px;
      // position: absolute;
      width: 100%;
      // background-color: red !important;  
      }
      .wrapper{
        height: 4.5rem;
       }
    
}



@media screen and (max-width: 525px) {
    
    .user_profile{
        display: none;
    }
    .agent_name ,.role{
        display: none;
    }
    .list{
        gap: .85rem;
        height: 2.8rem;
        padding: 0 1rem;
        width: 80vw;
        font-size: .75rem
      }
      .wrapper{
        height: 3.5rem;
       } 
     
}