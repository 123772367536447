@import "../../../styles/index.scss";

.backgroundWrapper{
    width: 100%;
    @include padding($--padding-xxl,$--padding-sm,$--padding-xxl,$--padding-sm);
    background-color: $--color-bg-white;
  

}

@media screen and (max-width:500px) {

    .backgroundWrapper{
     @include padding($--padding-xs,$--padding-xs,$--padding-xs,$--padding-xs);
     background-color: $--color-bg-white;
    
    }
    
}