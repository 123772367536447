@import "../../../styles/index.scss";
.title {
    @include title_text();
  }

  .question {
   
    @include title_question();
    // margin-bottom: .8rem;
  }

  .wrapper{
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .radio_button{
    @include grid(4,1rem);
  }


  @media screen and (max-width:500px) {

    .wrapper{
      gap: .5rem;
    }
    .radio_button{
      @include grid(2,1rem);
    }
  }