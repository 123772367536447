@import "../../../styles/index.scss";

.button{
    @include button($--color-bg-white, $--color-bg-black, $--border-style-none, $--border-radius-xs, $--padding-xs, $--font-size-md, $--font-weight-regular, $--border-style-none, $--color-bg-black);    
    border: 1px solid #DDD;
    height: 3.526rem;
    width: 100%;
    font-variant-numeric: lining-nums tabular-nums;
    font-family: $--font-primary;

}
.container{
    width: 23%;
    // flex-wrap: wrap;
    height: 3.526rem;
    // border: 1px solid red;
}
.wrapper{
    // width: 100%;
    display: flex;
    // flex-direction: row;
    // border: 1px solid red;
    // justify-content: space-between;
    align-items: center;
    // width: 100%;
    gap: 1rem;
    flex-wrap: wrap;
}
.bottomNav {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 1px -1px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    gap: 1.5rem;
    border: 1px solid red;
    // justify-content: space-between;
    padding: 0.5rem 0;
    background-color: $--color-primary;
}
.bottom_Nav_circle{
    border-radius: 50%;
    background-color: aquamarine;
    padding: .5rem;

}

@media screen and (max-width: 768px) {

    .wrapper{
        width: 100%;
        display: flex;
        flex-direction: row;
        // border: 1px solid red;
        justify-content: space-between;
        align-items: center;
    
    }
    .container{
        width: 48%;
    }

    .button{
        font-size:$--font-size-xs ;   
        width: 100%; 
        height:45px;
    }
    
}

@media screen and (max-width: 525px) {
    .container{
        width: 100%;
        height: 2.526rem;
    }
    .wrapper{
        gap: .2rem;
    }
    .button{
        font-size:$--font-size-xs ;   
        width: 100%; 
        height:35px;
        font-size: $--font-size-xxs;
    }
   
    
}
