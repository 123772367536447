.select {
    border: 1px solid #DDD !important;
    border-radius: 4px !important;
    padding: 5px 10px;
    // width: 100%;
    height: auto;

}

.wrapper {

    display: flex;
    justify-content: space-between;
    width: 80%;
    gap: 1rem;
    height: 100%;

}

.input {
    border-radius: 4px;
    border: 1px solid #DDD;
    padding: 8px 10px;
    width: 100%;
    min-height: 100%;

}

.button {
    max-width: 100%;
    padding: 6px 15px;
    border-radius: 4px;
    background: #D2F0F2;
    font-size: 18px;
    border: 1px solid white;
    color: #333;
}

.container {
    width: 32%;
    height: 100%;
}


@media screen and (max-width:560px) {
    .wrapper {
        width: 100% !important;

      flex-direction: column;
    }
    .button {
        padding: 5px 10px;
        font-size: 12px;
        min-height: 100%;
        
    }

    .wrapper {
        // border: 1px solid red;
        width: 100%;
        gap: .5rem;
        height: 100% !important;

    }


 
    .input::placeholder{
        
        font-size: 10px;
    }
    .input {
        width: 100% !important;
        height: 35px;
    }

    .select {
        // font-size: 10px;
        padding: 2px 5px;
        // height:100% !important;
        
    }
}