.input-focused {
    border: 2px solid #40a9ff;
    /* Highlight border color */
    box-shadow: 0 0 5px rgba(64, 169, 255, 0.5);
    /* Glow effect */
    transition: border-color 0.3s, box-shadow 0.3s;
    /* Smooth transition */
}

.input-blurred {
    border: 1px solid #d9d9d9;
    /* Normal border color */
    opacity: 0.6;
    /* Slightly blurred effect */
    transition: border-color 0.3s, opacity 0.3s;
    /* Smooth transition */
}