.input_wrapper {
  // border: none;
  padding: 8px 10px;
  // width: 100%; /
  border-radius: 5px;
}

.input_style {
  border: 0px solid #ad3e3e;
  display: inline-flex;
  outline: none;
}

.input_style input {
  border: #fff;
}

.wrapper {
  display: inline-flex;
  border: 1px solid #ddd;
  border-radius: 4px;
  // width: 22%;
  height: 100%;
}

.button {
  border-radius: 4px;
  background: #d2f0f2;
  padding: 8px 10px;
  border: 1px solid white;
  cursor: pointer;
  font-size: 18px;
  margin-left: 5px;
  min-height: 100%;
}

@media screen and (max-width: 500px) {
  .input_wrapper {
    // border: none;
    padding: 1px 3px;
    height: 100% !important;
    // width: 100%; /
    // border-radius: 5px;
  }

  .input_style::placeholder{
    font-size: 0.75rem;
    

  }

  .button {
    border-radius: 4px;   
    font-size: 0.75rem;
  }
}
