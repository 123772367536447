@import "../../styles/index.scss";

.logistics_wrapper {
    // @include padding(0, $--padding-lg, 0, $--padding-lg);
    margin-top: 1.5rem;

}

.flex {
    height: 1.8rem;
    //  border: 1px solid red;
}

.heading {
    @include heading();

}

.operation_wrapper {
    // border: 1px solid red;
    cursor: pointer;
    width: 50%;
}

.heading_operation {
  @include heading();
    color: rgba(51, 51, 51, 0.7);
}

.border {
    width: 100%;
    @include border(0.125rem, $--border-style-solid, $--color-heading);
}

.background {
    margin-top: 1rem;
    background: rgba(210, 240, 242, 0.32);
    height: auto;
}

.step {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
}

.step_button {
    color: $--color-bg-progress;
    background: rgba(72, 114, 213, 0.24);
    border-radius: 0.9rem;
    @include custom-font($--font-primary, $--font-weight-regular, $--font-size-sm, $--line-height-lg, 0, $--letter-spacing-lg);
    @include padding(0.375rem, $--padding-lg, 0.375rem, $--padding-lg);
    border: 1px solid $--color-bg-progress;
}
.container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.box{
    display: flex;
    gap: 1rem;
    flex-direction: column;

}
.call_logs{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

@media screen and (max-width:500px) {
    .step_button{
        font-size: $--font-size-xs;
        @include padding(0.175rem,$--padding-sm,0.175rem,$--padding-sm);
   
   }
   .background{
    background-color: white;
    padding: 0rem !important;
    // border: 1px solid red;
  }
  .logistics_wrapper {
   padding: 0;

}
    
}