@import "../../../styles/index.scss";



.flex {
  justify-content: flex-start;
  // gap: 2rem;
  height: 100%;
}

.prompt {
  border-radius: 0px 20px 20px 20px;
  border: 1px solid #ddd;
  background: rgba(252, 217, 236, 0.79);
  @include padding(1rem, 1rem, 1rem, 1rem);
}

.message {
  @include custom-font(
    $--font-primary,
    $--font-weight-regular,
    $--font-size-md,
    $--line-height-lg,
    $--letter-spacing-lg,
    $--letter-spacing-lg
  );
}
.icon_wrapper {
  width: 4.25rem;
  height: 4.25rem;
}

.icon {
  width: 4.25rem;
  height: 4.25rem;
  border-radius: 50%;
}


@media screen and (max-width:500px) {
    .icon_wrapper {
        width: 3rem;
        height: 3rem;
      }
      
      .icon {
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
      }
      .message {
        @include custom-font(
          $--font-primary,
          $--font-weight-regular,
          $--font-size-xxxs,
          0.8rem,
          normal,
          0.6px
        );
      }

      .prompt {
        border-radius: 0px 20px 20px 20px;
        border: 1px solid #ddd;
        background: rgba(252, 217, 236, 0.79);
        @include padding(0.4rem, 0.5rem, 0.5rem, 0.5rem);
        line-height:.5rem !important;
      }
      .flex{
        gap: .5rem;
 
      }
}

@media screen and (max-width:360px) {
    .flex{
        gap: .5rem;
        flex-direction: column;
    
 
}
}